import {AcademyTextBody} from "../academy/AcademyElements";
import {AdvisoryDivLineItemsMobile, CoverIcon, CoverIconContainer} from "../advisory/AdvisoryElement";
import {ScrollTop} from "../home/HomeElements";
import scrollTopIcon from "../../assets/images/ScrolltoTopIcon.png";
import {
    AboutHead,
    AboutUsBackground,
    AboutUsBody,
    AboutUsTitle,
    CompanyLogo,
    CompanyLogoContainers,
    MembersContainer,
    MembersRow,
    MembersWrapper,
    QuoteBackground,
    QuoteImg,
    QuoteSayer,
    QuoteText,
    StoryBackground,
    StoryContainer,
    StoryImg,
    StoryText,
    TextAboutHeader
} from "./AboutUsElements";
import {DigitalHeadLogo, HuntingHead} from "../darkivore/DarkivoreElements";
import whiteLettre from '../../assets/logos/whiteLettre.png'

import potechConsultingLogoH from '../../assets/companiesLogo/aboutPotechConsultingLogo.svg'
import itelicLogoH from '../../assets/companiesLogo/aboutItelicLogo.svg'
import obsoftLogoH from '../../assets/companiesLogo/aboutObsoftLogo.svg'
import potechAcademyLogoH from '../../assets/companiesLogo/aboutPotechAcademyLogo.svg'
import thidesoftLogoH from '../../assets/companiesLogo/aboutThidesoftLogo.svg'
import potechLabsLogoH from '../../assets/companiesLogo/aboutPotechLabsLogo.svg'
import NigmaLogo from '../../assets/companiesLogo/aboutNigmaLogo.svg'
import FooterDivider from "../../components/FooterDivider";
import membersIcon from '../../assets/logos/membersIcon.png';
import MembersCard from "../../components/MembersCard";

import {useState} from "react";
import {ModuleBorderWrap} from "../../components/MembersCard/MembersCardElements";

import tonyFeghali from '../../assets/potechMembers/Tony/5G2A8516d-200x200.png';
import anonymous from '../../assets/potechMembers/anonymous.png';
import hanadyKaram from '../../assets/potechMembers/Hanady/5G2A8552d-200x200.png';
import jadTabet from '../../assets/potechMembers/Jad/5G2A4113d-200x200.png';
import nadimHenoud from '../../assets/potechMembers/Nadim/5G2A4098d-200x200.png';
import elieZeidan from '../../assets/potechMembers/Elie/5G2A4151d-200x200.png';
import hadyKhoury from '../../assets/potechMembers/Hadi/Hadi El Khoury-200x200.png';
import LauriceSaliba from '../../assets/potechMembers/Laurice/5G2A4082d-200x200.png';
import rachelBalech from '../../assets/potechMembers/Rachelle/5G2A4109d-200x200.png';
import fayezSakr from '../../assets/potechMembers/Fayez/20240311_001123 new-200x200.png';
import alex from '../../assets/potechMembers/Alexandre/5G2A8656d-200x200.png';
import ralphBust from '../../assets/potechMembers/Ralph Boustani/5G2A8606d-200x200.png';
import dany from '../../assets/potechMembers/Dany/5G2A8806d-200x200.png';
import roudy from '../../assets/potechMembers/Roudayna/5G2A8561d-200x200.png';
import ali from '../../assets/potechMembers/Ali/5G2A4095d-200x200.png';
import touf from '../../assets/potechMembers/Toufic/5G2A8612d-200x200.png';
import amal from '../../assets/potechMembers/Amal/5G2A4090new-200x200.png';
import diana from '../../assets/potechMembers/Diana Jawabra/5G2A8542d-200x200.png';
import rawad from '../../assets/potechMembers/Rawad/5G2A8531d-200x200.png';
import shereen from '../../assets/potechMembers/Shereen/5G2A8586d-200x200.png';
import tania from '../../assets/potechMembers/Tania/5G2A8702d-200x200.png';
import wassim from '../../assets/potechMembers/Wassim/5G2A8579d-200x200.png';
import fabienTabarly from '../../assets/potechMembers/Fabien/fabientabarly.png';
import bassamKhoreich from '../../assets/potechMembers/BassamKhoreich/BassamKhoreich.png';

import {useRef} from "react";
import {useEffect} from "react";
import {Helmet} from "react-helmet";
import {useMediaQuery} from "react-responsive";

const AboutUs = ({isScrolled}) => {

    const [cardIndex, setCardIndex] = useState(null);
    const [text, setText] = useState(null);
    const [activeMember, setActiveMember] = useState(null);
    const isMobile = useMediaQuery({query: `(max-width: 800px)`});

    const clickHandler = (index, activeMember) => {
        if (cardIndex === index) {
            setCardIndex(null);
            setActiveMember(null);
        } else {
            setCardIndex(index);
            setActiveMember(activeMember);
        }
    }
    const storyRef = useRef(null);
    const top = useRef(null);

    useEffect(() => {
        top.current.scrollIntoView({behavior: 'smooth'});
    }, [])

    /**
     * * Function to convert a flat array into an array of arrays with a specified number of elements per sub-array.
     * @param arr
     * @param rowSize
     * @returns {*[]}
     */
    function convertArrayToRowsArray(arr, rowSize) {
        const result = [];
        for (let i = 0; i < arr.length; i += rowSize) {
            const chunk = arr.slice(i, i + rowSize);
            result.push(chunk);
        }
        return result;
    }

    const managementTeam = [
        {
            name: "Tony Feghali",
            position: "Group CEO",
            logo: tonyFeghali,
            description: <div>
                <div>Holder of a Bachelor of Science degree in Telecommunications from USJ - Université St Joseph –
                    Beirut and a Master’s degree in Networking & Distributed Systems from University of Paris 6, Tony
                    Feghali has been leading Potech group since 2002.
                </div>
                <p></p>
                <div>He is a lecturer at USJ since the year 2000, teaching courses related to networks & systems
                    management and cybersecurity.
                </div>
                <p></p>
                <div>As a researcher, Tony has participated in multiple research projects on ICT security, and presented
                    several papers and talks in international conferences.
                </div>
                <p></p>
                <div>A daring entrepreneur with a 25-years+ successful career, Tony is also an adrenaline chaser with
                    regular participations - and wins - in motor racing challenges.
                </div>
            </div>
        },
        {
            name: "Fabien Tabarly",
            position: "CEO - Nigma",
            logo: fabienTabarly,
            description: <div>
                <div>Fabien Tabarly is a retired Lieutenant Colonel who served 27 years in the French Airborne
                    Reconnaissance and Special Forces.
                </div>
                <p></p>
                <div>His extensive experience in war-torn countries and complex logistical setups have allowed him to
                    excel in planning and executing high-stakes missions with exceptional precision and strategic
                    prowess.
                </div>
            </div>
        },
        {
            name: "Nakhle Aoun",
            position: "Group CFO",
            description: <div>
                <div>Nakhle Aoun has a Bachelor’s degree in Economics and a Master’s degree in Economics with a focus on
                    Banking & Finance, from USJ - Université St Joseph – Beirut.
                </div>
                <p></p>
                <div>With a highly interesting corporate experience throughout various finance-related functions, Nakhle
                    stands out with his unwavering commitment to drive continuous improvement within the finance
                    function and across the business. By partnering with internal and external stakeholders, he’s always
                    there to provide spot-on insights and guidance.
                </div>
            </div>
        },
        {
            name: "Hanady Karam",
            position: "Group COO",
            summary: "Managing Partner - Potech Consulting",
            logo: hanadyKaram,
            description: <div>
                <div>After completing her Bachelor of Science degree in Telecommunications at USJ - Université St Joseph
                    – Beirut, Hanady Karam went on to pursue her Master's degree in Systems & Network Security from the
                    same university.
                </div>
                <p></p>
                <div>She is today a professor of Risk Management at USJ – Faculty of Engineering.</div>
                <p></p>
                <div>With over 14 years of experience in information systems security consultancy and evaluation; Hanady
                    has a strong technical expertise in IT governance, strategic planning, IT service management, risk
                    management, ISO 27001 and ISO 22301 compliance, data privacy and information security awareness.
                </div>
                <p></p>
                <div>She is now Potech group COO, responsible for the smooth running of operations within the group.
                </div>
                <p></p>
                <div>She also heads the group’s Marketing and Communications functions, in addition to her
                    responsibilities as Managing Partner of Potech Consulting, offering IT & cybersecurity advisory
                    services.
                </div>
                <p></p>
                <div>Hanady shows great enthusiasm for disconnecting in nature, traveling and discovering new countries
                    and cultures; a hobby which serves her passion for exploring new flavors from around the world.
                </div>
            </div>
        },
        {
            name: "Jad Tabet",
            position: "Group CSO",
            summary: "Managing Partner - ITelic <br /> General Manager – Potech Arabia",
            logo: jadTabet,
            description: <div>
                <div>
                    Jad Tabet has over 21 years of managerial experience in the ICT field and a strong technical
                    expertise in software engineering.
                </div>
                <p></p>
                <div>
                    Holder of a Master’s degree in Business Administration from the University of Sorbonne Paris VI, Jad
                    was also a part-time professor at the Faculty of Engineering of Université St Joseph – Beirut,
                    teaching Networking courses and tutoring research projects for 4th and 5th year engineering
                    students.
                </div>
            </div>
        },
        {
            name: "Nadim Henoud",
            position: "Group Head of Engineering",
            summary: "Managing Partner – OBSoft",
            logo: nadimHenoud,
            description: <div>
                <div>Nadim Henoud is a seasoned software engineer and cybersecurity expert with 15+ years of experience.
                    His technical knowledge and strategic agile expertise enable him to design scalable architectures
                    and deliver complex software solutions.
                </div>
                <p></p>
                <div>He’s a graduate of USJ - Université St Joseph – Beirut with a Master’s degree in Network and
                    Systems Security.
                </div>
                <p></p>
                <div>Moreover, Nadim is a Visiting Instructor at Telecom Paris, EPITA, ESIEA, and USJ Beirut, focusing
                    on topics related to Engineering, Security, AI, and Machine Learning.
                </div>
                <p></p>
                <div>When not juggling with engineering solutions, Nadim is exploring the great outdoors through diving,
                    horseback riding, and camping.
                </div>
            </div>
        },
        {
            name: "Elie Zeidan",
            position: "CPO & Managing Partner - Thidesoft",
            logo: elieZeidan,
            description: <div>
                <div>Elie received his Engineering Degree in Communication & Computer from ULFG before pursuing his
                    Master’s Degree in Systems and Networks Security at USJ - Université St Joseph – Beirut.
                </div>
                <p></p>
                <div>He has over 12 years of experience in information systems security consultancy and evaluation; and
                    a strong technical expertise in malware analysis, threat intelligence, digital forensics and source
                    code review.
                </div>
                <p></p>
                <div>Elie is a Certified Ethical Hacker, and a Cyber Crime and Ethical Hacking Instructor at USJ,
                    teaching: Information Security Concepts, Hacking Phases, Attack Vectors & Advanced Techniques,
                    Hardening & Defensive Security.
                </div>
                <p></p>
                <div>A passionate professional with a creative edge; when not working, Elie is strumming his guitar or
                    painting his own designs.
                </div>
            </div>
        },
        {
            name: "Hadi El Khoury",
            position: "Managing Partner – Potech Academy",
            logo: hadyKhoury,
            description: <div>
                <div>For the last 20 years, Hadi El Khoury has been advising and training individuals and organizations
                    across the world, from both the private and public sectors, on cybersecurity, compliance and digital
                    risk management.
                </div>
                <p></p>
                <div>Hadi has received multiple awards and distinctions; he regularly speaks at conferences and
                    publishes articles.
                </div>
                <p></p>
                <div>He's a co-founding member of the Information Systems Security Association's Chapter in France and
                    also serves as a civilian reservist in the French Army's Cyberdefense unit.
                </div>
                <p></p>
                <div>Hadi holds a Telecom Engineering diploma from the Beirut School of Engineering at USJ - Université
                    St Joseph and a Master’s degree in Information Security from Telecom ParisTech.
                </div>
            </div>
        },
        {
            name: "Dany Mezher",
            position: "Partner - Research & Development Director",
            logo: dany,
            description: <div>
                <div>Dany Mezher received his Electrical Engineering Degree with a major in Software Engineering, from
                    USJ - Université St Joseph – Beirut.
                </div>
                <p></p>
                <div>After obtaining a Master’s degree in Applied Mathematics, he earned a PhD in High Performance
                    Computing from INRIA - Institut National de Recherche en Informatique et Automatique - France.
                </div>
                <p></p>
                <div>He is currently the Rector’s Advisor for IT Affairs at USJ, and a full-time professor at the same
                    university, teaching Applied Mathematics, Artificial Intelligence, Data Mining, Big Data courses,
                    and various programming-related courses.
                </div>
                <p></p>
                <div>Dany’s research interests gravitate around High-Performance Computing, Artificial Intelligence and
                    Data Analytics.
                </div>
            </div>
        },
        {
            name: "Amal Mezher",
            position: "Partner - Head of Corporate Legal Affairs",
            logo: amal,
            description: <div>
                <div>Amal Mezher holds a Master of Sciences in Applied Mathematics & Scientific Computing, a combined
                    degree from Université St Joseph, the Lebanese University, Rennes 1 University, Reims University
                    France, and Ecole Polytechnique Fédérale de Lausanne.
                </div>
                <p></p>
                <div>She also holds another MSc in Computer Science from the Lebanese University.</div>
                <p></p>
                <div>After starting her career in software development, Amal became a programming instructor at
                    Université St Joseph, before embarking on a career turn with Potech, today managing the group’s
                    Legal Affairs, ensuring compliance with laws and regulations and providing administrative support to
                    the legal team.
                </div>
            </div>
        },
        {
            name: "Rachelle Balech",
            position: "Partner - Business & Channel Development Director",
            logo: rachelBalech,
            description: <div>
                <div>Rachelle Balech is a graduate of Université St Joseph – Beirut, with an Electrical and Mechanical
                    Engineering degree.
                </div>
                <p></p>
                <div>With more than 15 years of multinational experience in sales, Rachelle excels at building and
                    maintaining long-lasting customer/partner relations.
                </div>
                <p></p>
                <div>Today she plays a focal role between Potech group and its partners, developing strong business
                    relationships with channel partners, effectively identifying business requirements and translating
                    their unique needs into tailored solutions.
                </div>
                <p></p>
                <div>A young mother of two, Rachelle also thrives at playing tennis and basketball, reading and
                    travelling.
                </div>
            </div>
        },
        {
            name: "Roudayna Feghali",
            position: "Head of HR & Administration",
            logo: roudy,
            description: <div>
                <div>Roudayna Feghali is a Computer & Communications Engineer, graduate of Université Antonine – Beirut,
                    with a Professional Master 2 degree in Interactive Multimedia from Université Paris 1, Panthéon
                    Sorbonne – Paris.
                </div>
                <p></p>
                <div>After starting her career at Potech 10 years ago with a focus on accounting, Roudayna is now
                    heading HR & Administration, ensuring a smooth running of the group’s administrative activities
                    through office and facility management, staff development, and technical support within the
                    different entities of the group.
                </div>
                <p></p>
                <div>Roudayna is also @rudylicious_lb, a sparkling cook and food expert on Instagram!</div>
                <p></p>
                <div>She also enjoys attending movie theaters and car racing competitions.</div>
            </div>
        },
        {
            name: "Fayez Sakr",
            position: "Partner – Group CTO",
            logo: fayezSakr,
            description: <div>
                <div>Fayez Sakr has a vast experience in information systems security consultancy, with almost a decade
                    of technical expertise in penetration testing, digital forensics, malware analysis, cyber threat
                    intelligence and digital risk protection.
                </div>
                <p></p>
                <div>He holds a Master’s degree in Network & Information Systems Security from Université St Joseph –
                    Beirut where he currently lectures on Networks and Systems Security.
                </div>
                <p></p>
                <div>On the rare occasions when he’s caught with his guard down, Fayez is roaming the globe in pursuit
                    of thrilling adventures.
                </div>
            </div>
        },
        {
            name: "Alexandre Fakhoury",
            position: "Partner - Cyber Strategy Manager",
            logo: alex,
            description: <div>
                <div>Alex Fakhoury received his Bachelor’s degree in Telecommunication & Information and his Master’s
                    degree in Cybersecurity from USJ - Université St Joseph – Beirut.
                </div>
                <p></p>
                <div>He also holds an MBA from IAE Paris – Sorbonne Business School, Université Paris Dauphine and USJ.
                </div>
                <p></p>
                <div>He obtained his ISO27001 and ISO27005 Lead Manager certifications in 2022 and 2023 respectively.
                </div>
                <p></p>
                <div>With over 7 years of experience in information systems security consultancy and evaluation, Alex
                    presents a strong technical expertise in ISMS implementation and risk management.
                </div>
                <p></p>
                <div>When loosening up from cyber issues, he plays basketball, petanque and table tennis, or simply
                    enjoys the serenity of reading interesting books.
                </div>
            </div>
        },
        {
            name: "Ali Kanso",
            position: "SOC Manager",
            logo: ali,
            description: <div>
                <div>With over 7 years of experience in information systems security consultancy and compliance and a
                    strong technical expertise in SIEM and SOC, Ali Kanso currently leads at Potech a team of skilled
                    professionals that offer clients a robust protection against cyberthreats.
                </div>
                <p></p>
                <div>Ali holds a Master’s degree in Cybersecurity and an MBA in Business Administration, Management and
                    Operations from Université St Joseph – Beirut.
                </div>
                <p></p>
                <div>His very own way of leaving stress and threats behind is by playing football and swimming.</div>
            </div>
        },
        {
            name: "Diana Jawabra",
            position: "Accounting Manager",
            logo: diana,
            description: <div>
                <div>Diana Jawabra holds a Bachelor’s degree in Banking and Financial Support Services from the American
                    University of Science & Technology - Beirut.
                </div>
                <p></p>
                <div>With 20+ years of experience in the field, Diana has successfully managed accounting functions in
                    the FMCG, medical and industrial fields, and is impeccably leading the group accounting services at
                    Potech.
                </div>
            </div>
        },
        {
            name: "Laurice Saliba",
            position: "Head of Corporate Governance & Business Excellence",
            logo: LauriceSaliba,
            description: <div>
                <div>Laurice Saliba started her career as an Electrical Engineer, graduating from the Lebanese
                    University, before receiving her Master’s degree in Renewable Energy from the Beirut School of
                    Engineering at USJ - Université St Joseph.
                </div>
                <p></p>
                <div>She then wholeheartedly shifted to handling corporate governance at Potech, with a mission to
                    maintain compliance with legal and internal policies.
                </div>
                <p></p>
                <div>Laurice is responsible for developing and overseeing control systems, revising procedures and
                    reports, drafting and implementing company policies, and coordinating Governance Committee and Board
                    meetings.
                </div>
                <p></p>
                <div>When taking a break from organizational work, Laurice finds pleasure in baking her own creations,
                    or also reading and playing Badminton.
                </div>
            </div>
        },
        {
            name: "Ralph Boustani",
            position: "Cyber Intelligence Unit Manager",
            logo: ralphBust,
            description: <div>
                <div>Ralph Boustani received his Bachelor of Science degree in Engineering from Université St Joseph –
                    Beirut before obtaining a Master's degree in Cybersecurity from the same university.
                </div>
                <p></p>
                <div>He has over 7 years of experience in information systems security consultancy and evaluation, and a
                    strong technical expertise in penetration testing and digital forensics.
                </div>
                <p></p>
                <div>Ralph provides trainings on topics such as Passive Reconnaissance, Cyberthreat Intelligence, Social
                    Engineering, Penetration Testing and Digital Forensics.
                </div>
                <p></p>
                <div>Other than cybersecurity, Ralph’s passions go for jamming on various musical instruments, and also
                    reading and camping in the wild.
                </div>
            </div>
        },
        {
            name: "Rawad Mekdad",
            position: "Presales Manager",
            logo: rawad,
            description: <div>
                <div>With 19+ years of experience in professional sales support services, Rawad Mekdad is a Computer
                    Communication Engineer with proven expertise in developing sales collaterals and presenting sales
                    proposals.
                </div>
                <p></p>
                <div>Holder of an MBA from ESA – Ecole Supérieure des Affaires – Beirut, Rawad is a results-driven
                    communicator and negotiator with high critical thinking skills, and a forte in serving fast-paced
                    business environments.
                </div>
            </div>
        },
        {
            name: "Shereen Qardan",
            position: "Communications Manager",
            logo: shereen,
            description: <div>
                <div>Shereen Qardan is a creative professional with a diverse background in omni-channel communications,
                    spanning the client and agency sides.
                </div>
                <p></p>
                <div>Holder of a Bachelor’s degree in Graphic Design and a Postgraduate Diploma in Copywriting &
                    Marketing Communications, Shereen’s professional and academic journey has taken her through Jordan,
                    South Africa, the UAE, and Lebanon.
                </div>
                <p></p>
                <div>In her current role at Potech, Shereen is entrusted with the strategic and creative aspects of the
                    Group’s brand communications.
                </div>
                <p></p>
                <div>Her entrepreneurial inclination is reflected in various ventures, as remarkable as authoring a
                    dictionary of Arabic colloquial expressions to co-founding a startup in the UAE.
                </div>
                <p></p>
                <div>In her free time, she indulges in her passion for art, finding joy in creating impasto artworks.
                </div>
            </div>
        },
        {
            name: "Tania Moukarzel",
            position: "Senior Executive Officer – Office of the CEO",
            logo: tania,
            description: <div>
                <div>After acquiring her Bachelor's degree in Economics from Université St Joseph - Beirut, Tania
                    Moukarzel found her passion in helping clients achieve their financial goals.
                </div>
                <p></p>
                <div>With over 11 years of experience as a banking relationship manager, Tania has made a notable
                    transition to the cybersecurity world, today serving as Senior Executive Officer for the CEO office.
                </div>
                <p></p>
                <div>Leveraging her proven skills in effective communications, relationship building and strategic
                    thinking, Tania provides an invaluable organizational support to Potech, within a dynamic and
                    fast-paced industry.
                </div>
            </div>
        },
        {
            name: "Toufic Fakhry",
            position: "Software Engineering Manager",
            logo: touf,
            description: <div>
                <div>Toufic Fakhry is a seasoned software engineering expert with over 10 years of experience in the
                    tech industry.
                </div>
                <p></p>
                <div>He is responsible for leading a team of engineers in the development and delivery of innovative
                    software products. Toufic has a deep understanding of the software development process, he is
                    passionate about helping teams build high-quality engineering tools that meet the needs of their
                    users.
                </div>
                <p></p>
                <div>Toufic holds a Bachelor of Science degree in Computer Science from Notre Dame University – Lebanon,
                    he is also a part-time instructor at Université St Joseph - Beirut, teaching Mobile App Development
                    in Android.
                </div>
                <p></p>
                <div>In his spare time, he enjoys playing the bass with his jazz band, hiking and spending time with
                    family.
                </div>
            </div>
        },
        {
            name: "Wassim Renno",
            position: "Product Marketing Manager",
            logo: wassim,
            description: <div>
                <div>Wassim Renno heads the development and execution of marketing plans for Potech products, his
                    role involves identifying creative initiatives that support sales growth.
                </div>
                <p></p>
                <div>Holder of a Bachelor’s degree in Advertising & Marketing from Université St Joseph – Beirut, Wassim
                    has carved a successful path in commercial business development within the FMCG sector.
                </div>
                <p></p>
                <div>Building on his passion for storytelling and creative thinking, Wassim is focused on conducting
                    market research and introducing innovative solutions in order to strengthen the products reputation,
                    and increase its market share.
                </div>
                <p></p>
                <div>With Potech, Wassim is determined to raise awareness and empower businesses to view cybersecurity
                    not just as a protection measure, but as a driver for revenue growth.
                </div>
            </div>
        },
        // {
        //     name: "Bassam Khoreich",
        //     position: "Managing Partner – Potech Australia",
        //     logo: bassamKhoreich,
        //     description: <div>
        //         <div>With 20+ years of experience and ongoing technical certifications, Bassam Khoreich has this unique sparkle that brings together passionate academic knowlegde and practical involvement in the cybersecurity field.<br/>At Potech Australia, he is driving sustainable growth by focusing on customer success through effective protection and prevention strategies.</div>
        //         <p></p>
        //         <div>He holds a Master’s degree in Systems & Networks Security from Université St Joseph – Beirut and an MBA from the Middle East University – Lebanon. Dedicated to continuous learning, Bassam engages in academic research and shares his knowledge as a lecturer at respected universities. His current research interests include infrastructure and cybersecurity.</div>
        //         <p></p>
        //         <div>Bassam’s passions also extend to soccer, basketball and table tennis. A dedicated volunteer, he embraces opportunities to give back, actively engaging in community initiatives and striving to make a meaningful difference wherever he can.</div>
        //     </div>
        // }
    ];
    const managementTeamRows = convertArrayToRowsArray(managementTeam, isMobile ? 1 : 3);
    return <>
        <Helmet>
            <title>POTECH: About Us</title>
            <meta name="description"
                  content="A group of seven entities providing a wide range of Cybersecurity and Information & Technology solutions."/>
        </Helmet>
        <AboutUsBackground ref={top}>
            <AboutUsTitle>
                <TextAboutHeader>
                    ABOUT US
                </TextAboutHeader>
                <AcademyTextBody>
                    At Potech, our people are not just technical experts; they are a team of passionate visionaries,
                    innovative, creative, dynamic, ambitious and generous professionals.
                </AcademyTextBody>
                <AcademyTextBody>
                    Working collaboratively towards a common purpose and shared goals, they stand by your side,
                    providing unparalleled support.
                </AcademyTextBody>
                {/* <CoverIconContainer style={{ width: '0vw', marginTop: '5vh' }}>
                    <CoverIcon src={StoryLogo} style={{ width: '14vw' }} onClick={() => storyRef.current.scrollIntoView({ behavior: 'smooth' })} />
                    <CoverIcon src={MangementLogo} style={{ width: '14vw' }} />
                </CoverIconContainer> */}
                <ScrollTop isScrolled={isScrolled} src={scrollTopIcon} onClick={() => {
                    window.scrollTo(0, 0)
                }}/>
            </AboutUsTitle>
        </AboutUsBackground>
        <StoryBackground ref={storyRef}>
            <StoryImg/>
            <StoryContainer>
                <DigitalHeadLogo src={whiteLettre}/>
                <AboutHead>
                    THE STORY
                </AboutHead>
                <AboutUsBody>
                    <StoryText>
                        Our journey began in 2002 when a group of talented and young professionals recognized the
                        growing demand for web services, online applications, and cybersecurity solutions.
                        <AdvisoryDivLineItemsMobile/>
                        In a remarkably short time, we adapted to the evolving landscape of information and technology,
                        delivering world-class services, and gaining invaluable experience.
                        <AdvisoryDivLineItemsMobile/>


                        Our success attracted prestigious and innovative partners, propelling the company further onto
                        the global scene.
                        <AdvisoryDivLineItemsMobile/>


                        Today, Potech – Paths of Technology - is a parent company harnessing 7 specialized entities:
                    </StoryText>
                    <CompanyLogoContainers>
                        <CompanyLogo src={potechConsultingLogoH}/>
                        <CompanyLogo src={itelicLogoH}/>
                        <CompanyLogo src={obsoftLogoH}/>
                        <CompanyLogo src={potechAcademyLogoH}/>
                        <CompanyLogo src={thidesoftLogoH}/>
                        <CompanyLogo src={potechLabsLogoH}/>
                        <CompanyLogo src={NigmaLogo}/>
                    </CompanyLogoContainers>
                    <StoryText>
                        Potech’s line of activities is a diverse one, covering IT & Cybersecurity advisory services
                        (from Penetration Tests to strategic planning), advanced training services, R&D labs, Security
                        Operations Center SOC & Managed Services, superior Engineering Solutions & Tools as well as
                        Physical Security & Blockchain advisory services.
                        <AdvisoryDivLineItemsMobile marginTop={'3vh'}/>

                        Covering multiple markets across the MENA, Europe, Africa, Australia, America and the world,
                        Potech’s name is linked to masterful services in Information & Technology and Cybersecurity,
                        marked with an ongoing commitment to knowledge-sharing seldom seen among technical giants.
                        <AdvisoryDivLineItemsMobile marginTop={'3vh'}/>


                        As we continue to grow, we remain dedicated to pushing boundaries and exploring new territories.
                        Our presence in different markets across the globe demonstrates our commitment to serving a
                        diverse range of clients.
                        <AdvisoryDivLineItemsMobile marginTop={'3vh'}/>


                        Yes, the excellence of the product is important. But what’s more important is the peace of mind
                        clients get knowing they have a partner to rely on, every step of the way.
                        <AdvisoryDivLineItemsMobile marginTop={'3vh'}/>


                        Potech. A Partnership that Clicks.
                    </StoryText>
                </AboutUsBody>
            </StoryContainer>
        </StoryBackground>
        <QuoteBackground>
            <QuoteImg/>
            <QuoteText>
                “It’s all about a team of creative, highly motivated experts. A culture of excellence, innovation and
                ethics. And a Passion for solving, learning, and sharing.”
            </QuoteText>
            <QuoteSayer>
                Tony Feghali<br/>
                Group CEO
            </QuoteSayer>
        </QuoteBackground>
        <AboutUsBackground style={{justifyContent: 'center', height: 'auto'}}>
            <MembersContainer style={{marginTop: '25vh', marginBottom: '20vh'}}>
                <DigitalHeadLogo src={membersIcon}/>
                <HuntingHead style={{marginTop: '15vh', marginBottom: '5vh', color: '#002F87', fontSize: '30px'}}>
                    THE MANAGEMENT
                </HuntingHead>
                <div style={{display: 'flex', flexDirection: 'column', width: '64.5vw', marginTop: '15vh'}}>
                    {
                        //looping on rows
                        managementTeamRows.map((rowArr, rowIndex) => (
                                <>
                                    <MembersWrapper data-info='members-wrapper'>
                                        <MembersRow data-info='members-row'>
                                            {
                                                //looping on each row items
                                                rowArr.map((member, index) => {
                                                        //card index in flatten items
                                                        const cardGlobalIndex = 3 * rowIndex + index;
                                                        return <MembersCard
                                                            key={index}
                                                            clickHandler={() => clickHandler(cardGlobalIndex, member)}
                                                            logo={member.logo ?? anonymous}
                                                            name={member.name}
                                                            position={member.position}
                                                            desc={member.summary}
                                                            active={cardIndex === cardGlobalIndex}/>
                                                    }
                                                )
                                            }
                                        </MembersRow>
                                        {
                                            activeMember && (Math.floor(cardIndex / 3)) == rowIndex &&
                                            <ModuleBorderWrap data-info='content-card' active={true} style={{
                                                width: '60vw',
                                                height: 'auto',
                                                textAlign: 'start',
                                                textTransform: 'none',
                                                marginTop: '7vh'
                                            }}>
                                                <div style={{
                                                    backgroundColor: 'white',
                                                    width: '60vw',
                                                    borderRadius: '12px',
                                                    boxShadow: '2px 2px 15px 2px rgba(0, 0, 0, 0.20)',
                                                    color: 'black',
                                                    boxSizing: 'border-box',
                                                    padding: '2%',
                                                    fontSize: '1vw'
                                                }}>
                                                    <div style={{
                                                        color: 'var(--black, #000)',
                                                        fontFamily: 'Helvetica',
                                                        fontSize: '16px'
                                                    }}
                                                    >
                                                        {activeMember.description}
                                                    </div>
                                                </div>
                                            </ModuleBorderWrap>
                                        }
                                    </MembersWrapper>
                                </>
                            )
                        )
                    }
                </div>
            </MembersContainer>
        </AboutUsBackground>

        <FooterDivider/>
    </>
}

export default AboutUs;